body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: Roboto2;
  src: url(assets/fonts/Roboto/Roboto-Light.ttf);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.round {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid grey;
  cursor: pointer;
}

.round.active {
  border: 3px solid #2fb5dd !important;
}

.appearence_choose {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

#phone_container {
  width: 276px/*calc(828px / 3)*/;
  height: 512px/*calc(1792px / 3.5)*/;
  border: 2px grey solid;
  border-radius: 16px;
  background-color: #f2f2f2;
  position: relative;
  font-family: Roboto !important;
}

.status_bar {
  width: 272.5px;
  height: 17px;
  background-color: #f2f2f2;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: absolute;
  z-index: 6;
}

.bottom_tab {
  width: 272.5px;
  height: 36px;
  background-color: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  position: absolute;
  bottom: 0px;
  z-index: 6;
  display: flex;
  justify-content: space-between;
}
.bottom_tab_case {
  width: 33.33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.bottom_tab_case svg {
  height: 17px;
  margin-top: 5px;
  fill: #999;
}
.bottom_tab_case svg.active {
  fill: #2fb5dd;
}
.bottom_tab_case label {
  font-size: 8px;
  color: #999;
}
.bottom_tab_case label.active {
  color: #2fb5dd;
}

.home_scrollable_container {
  height: calc(100% - 81px);
}

.home_heading_image {
  width: 272.5px;
  height: 175px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
  background-color: grey;
  background-size: cover;
}

.home_heading_infos {
  width: 95%;
  height: 78px;
  padding: 8px;
  padding-top: 7px;
  margin-top: -40px;
  z-index: 99;
  position: absolute;
  /* position: relative; */
  left: 2.5%;
  /* bottom: 40px; */
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.home_heading_infos #title {
  font-weight: 700;
  font-size: 14px;
}
.home_heading_infos #address {
  font-size: 9px;
  color: #121212;
  margin-top: 3px;
}
.home_heading_infos #schedule {
  font-size: 10px;
  color: #121212;
  margin-top: 13px;
  font-family: Roboto2;
  font-weight: 600;
}

.home_section {
  width: 100%;
}
.home_section_title {
  font-weight: 700;
  font-size: 14px;
  margin-left: 2.5%;
  margin-top: 10px;
  margin-bottom: 14px;
}
.home_section_case {
  width: 100%;
  height: 72px;
  background-color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.home_section_case img {
  height: 100%;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.home_section_case span {
  margin-left: 10px;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.home_section_case span#title {
  font-weight: 600;
}
.home_section_case span#description {
  font-size: 9px;
  position: relative;
  bottom: 2px;
}
.home_section_case span#price {
  font-weight: 800;
  font-family: Roboto;
  color: #2fb5dd;
}

.add_to_basket_container {
  position: absolute;
  bottom: 36px;
  width: 100%;
  height: 45px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
}
.add_to_basket_container div#button {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 22px;
}
.add_to_basket_container span {
  color: #fff;
  font-size: 11px;
  font-weight: bold;
}
.add_to_basket_container span#items {
  font-weight: normal;
  margin-left: 8px;
  font-size: 10px;
}

.dzu-previewContainer {
  display: none !important;
}
.dzu-previewContainer:first-child {
  display: flex !important;
}